<template class="mr-3">
  <div class="card">
    <div class="card-body">
      <div>
        <p>{{ $t("recette.mois") }}</p>
        <a-select
          id="selectMonth"
          show-search
          :placeholder="$t('recette.tousMois')"
          option-filter-prop="children"
          style="width: 200px; margin-bottom: 20px"
          :filter-option="filterOption"
          @change="handleChangeSelectMois"
        >
          <a-select-option value="all">
            {{ $t("recette.tousMois") }}
          </a-select-option>
          <a-select-option value="01"> {{ moment.months(0) }} </a-select-option>
          <a-select-option value="02"> {{ moment.months(1) }} </a-select-option>
          <a-select-option value="03"> {{ moment.months(2) }} </a-select-option>
          <a-select-option value="04"> {{ moment.months(3) }} </a-select-option>
          <a-select-option value="05"> {{ moment.months(4) }} </a-select-option>
          <a-select-option value="06"> {{ moment.months(5) }} </a-select-option>
          <a-select-option value="07"> {{ moment.months(6) }} </a-select-option>
          <a-select-option value="08"> {{ moment.months(7) }} </a-select-option>
          <a-select-option value="09"> {{ moment.months(8) }} </a-select-option>
          <a-select-option value="10">
            {{ moment.months(9) }}
          </a-select-option>
          <a-select-option value="11">
            {{ moment.months(10) }}
          </a-select-option>
          <a-select-option value="12">
            {{ moment.months(11) }}
          </a-select-option>
        </a-select>

        <a-button
          type="file-pdf"
          @click="generatePdf"
          style="margin-bottom: 20px; float: right"
        >
          <a-icon type="file-pdf" @click="generatePdf" />
          {{ $t("action.imprimerTab") }}
        </a-button>
        <json-csv
          class="ant-btn"
          style="margin-right: 20px; float: right"
          :data="csvToExport"
          :name="'Etat impayés des activités.csv'"
          ><a-icon
            class="anticon mt-2"
            style="color: green"
            type="file-excel"
          /><span>CSV</span></json-csv
        >
      </div>
      <div>
        <a-table
          @change="tableChanged"
          rowKey="_id"
          :pagination="true"
          :data-source="data"
          :columns="columns"
          :loading="loadingData"
        >
          <template
            slot="expandedRowRender"
            slot-scope="record"
            style="margin: 0"
          >
            <table class="table table-striped">
              <thead>
                <th>{{ $t("recette.mois") }}</th>
                <th>{{ $t("recette.montant") }}</th>
                <th>{{ $t("topBar.status") }}</th>
              </thead>
              <tbody>
                <tr v-if="mF">
                  <td>{{ $t("recette.inscription") }}</td>
                  <td>
                    <del v-if="record.reduction.inscription > 0">
                      {{ record.activity.inscription }}
                    </del>
                    <div v-else>{{ record.activity.inscription }}</div>
                    <sup
                      v-if="record.reduction.inscription > 0"
                      class="text-success"
                      >-{{ record.reduction.inscription }}%</sup
                    >
                    <div v-if="record.reduction.inscription > 0">
                      ={{
                        record.activity.inscription -
                        (record.activity.inscription *
                          record.reduction.inscription) /
                          100
                      }}
                    </div>
                  </td>
                  <td>
                    <a-tag :color="!record.inscription ? 'green' : 'red'">
                      <b v-if="!record.inscription">{{ $t("profil.payes") }}</b>
                      <b v-else> {{ $t("profil.nonPayes") }}</b>
                    </a-tag>
                  </td>
                </tr>
                <tr v-for="(month, key) in record.months" :key="key">
                  <td>
                    {{ getMonthName(key) }}
                    <small>({{ paymentDate(record.start, key) }})</small>
                  </td>
                  <td>
                    <div>
                      {{ record.activity.monthly }}
                      <sup
                        v-if="record.reduction.monthly > 0"
                        class="text-success"
                        >-{{ record.reduction.monthly }}%</sup
                      >
                      <div v-if="record.reduction.monthly > 0">
                        ={{
                          record.activity.monthly -
                          (record.activity.monthly * record.reduction.monthly) /
                            100
                        }}
                      </div>
                    </div>
                  </td>
                  <td>
                    <a-tag :color="record.months[key] ? 'green' : 'red'">
                      <b v-if="record.months[key]">{{ $t("profil.payes") }}</b>
                      <b v-else> {{ $t("profil.nonPayes") }}</b>
                    </a-tag>
                  </td>
                </tr>
              </tbody>
            </table>
          </template>
          <template slot="name" slot-scope="text, record">
            {{ record.service.name }}: {{ text }}
          </template>
          <template slot="parent" slot-scope="text, record">
            {{ record.parent.father.firstName }}
            {{ record.parent.father.lastName }}<br />
            {{ record.parent.mother.firstName }}
            {{ record.parent.mother.lastName }}
          </template>
          <template slot="phone" slot-scope="text, record">
            {{ record.parent.father.phone }}<br />
            {{ record.parent.mother.phone }}
          </template>
          <template slot="student" slot-scope="text, record">
            {{ record.student.firstName }} {{ record.student.lastName }}
          </template>
          <!--
      <template
        v-for="col in [
          'nomClient',
          'telephone',
          'enfant',
          'frais',
          'montant',
        ]"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="(e) => handleChange(e.target.value, record.key, col)"
          />
          <template v-else>{{ text }}</template>
        </div>
      </template>
      -->
          <div
            slot="filterDropdown"
            slot-scope="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
            style="padding: 8px"
          >
            <a-input
              v-ant-ref="(c) => (searchInput = c)"
              :placeholder="`${$t('personnel.chercher')} ${column.title}`"
              :value="selectedKeys[0]"
              style="width: 188px; margin-bottom: 8px; display: block"
              @change="
                (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
              "
              @pressEnter="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
            />
            <a-button
              type="primary"
              icon="search"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="
                () => handleSearch(selectedKeys, confirm, column.dataIndex)
              "
              >{{ $t("paiement.chercher") }}</a-button
            >
            <a-button
              size="small"
              style="width: 90px"
              @click="() => handleReset(clearFilters)"
              >{{ $t("action.reinitialiser") }}</a-button
            >
          </div>
          <a-icon
            slot="filterIcon"
            slot-scope="filtered"
            type="search"
            :style="{ color: filtered ? '#108ee9' : undefined }"
          />
          <template
            slot="customRender"
            slot-scope="text, record, index, column"
          >
            <span v-if="searchText && searchedColumn === column.dataIndex">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="delete" slot-scope="text, record">
            <a-popconfirm
              :title="$t('all.sureToDelete')"
              @confirm="() => onDelete(record.key)"
            >
              <a-button type="danger">
                <a-icon type="delete" href="javascript:;" />{{
                  $t("action.supprimer")
                }}
              </a-button>
            </a-popconfirm>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import apiClient from "@/services/axios";
import JsPDF from "jspdf";
import "jspdf-autotable";
import writtenNumber from "written-number";
import { mapState } from "vuex";
import JsonCsv from "vue-json-csv";
import moment from "moment";

const getStatus = (entry) => {
  if (!entry.inscription) return "impayed";
  else {
    if (entry.once == false && entry.months) {
      for (const [key, value] of Object.entries(entry.months)) {
        if (!value) return "impayed";
      }
    }
    return "payed";
  }
};

const activityFormater = (activityFees) => {
  return {
    _id: activityFees._id,
    name: activityFees.activity[0].designation,
    once: activityFees.activity[0].once,
    inscription: activityFees.inscription,
    months: activityFees.months,
    reduction: activityFees.reduction,
    activity: activityFees.activity[0],
    service: activityFees.service[0],
    student: activityFees.student[0],
    parent: activityFees.parent[0],
    start: activityFees.start,
    type: "activity",
  };
};

const getMonthName = (monthString) => {
  const months = {};
  const monthsArr = moment.months();
  for (let i = 0; i < monthsArr.length; i++) months[i + 1] = monthsArr[i];
  return monthString ? months[parseInt(monthString.split("-")[1])] : null;
};
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  components: {
    JsonCsv,
  },
  async created() {
    apiClient
      .post("/students/activity/payment/filter", {
        query: {
          status: { $ne: "inactive" },
        },
        aggregation: [
          {
            $lookup: {
              from: "activities",
              localField: "activity",
              foreignField: "_id",
              as: "activity",
            },
          },
          {
            $lookup: {
              from: "students",
              localField: "student",
              foreignField: "_id",
              as: "student",
            },
          },
          {
            $lookup: {
              from: "parents",
              localField: "student.parent",
              foreignField: "_id",
              as: "parent",
            },
          },
          {
            $lookup: {
              from: "services",
              localField: "activity.service",
              foreignField: "_id",
              as: "service",
            },
          },
          {
            $project: {
              inscription: 1,
              reduction: 1,
              months: 1,
              service: { name: 1 },
              student: {
                firstName: 1,
                lastName: 1,
              },
              parent: {
                father: 1,
                mother: 1,
              },
              activity: {
                _id: 1,
                once: 1,
                inscription: 1,
                monthly: 1,
                start: 1,
                end: 1,
                designation: 1,
                order: 1,
              },
              start: 1,
            },
          },
        ],
      })
      .then((res) => {
        let dataList = res.data.map((elem) => activityFormater(elem));
        dataList.forEach((elem) => {
          const status = getStatus(elem);
          if (status != "payed") {
            this.data.push({
              ...elem,
              status: status,
            });
            this.rowData.push({
              ...elem,
              status: status,
            });
          }
        });
        this.loadingData = false;
      });
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" }, //schoolarYear: this.settings.activeSchoolarYear
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );
    this.cacheData = this.data.map((item) => ({ ...item }));
    this.filtredTable = this.data;
  },
  computed: {
    ...mapState(["settings"]),
    csvToExport() {
      const data = [...this.filtredTable];

      return data.map((item) => {
        var totale = item.activity.inscription;
        let nonPaye = 0;
        let paye = 0;
        if (item.inscription) nonPaye += item.activity.inscription;
        else paye += item.activity.inscription;
        _.forEach(item.months, (value, key) => {
          totale += item.activity.monthly;
          if (value) {
            paye += item.activity.monthly;
          } else {
            nonPaye += item.activity.monthly;
          }
        });
        return {
          nomClient: `${item.parent.father.firstName} ${item.parent.father.lastName} `,
          telephone: `Pére : ${item.parent.father.phone} mére: ${item.parent.mother.phone}`,
          enfant: `${item.student.firstName} ${item.student.lastName}`,
          activity: `${item.activity.designation} : ${item.name}`,
          montantTotale: totale,
          montantPayé: paye,
          montantNonPaye: nonPaye,
        };
      });
    },
  },
  data() {
    return {
      moment,
      mF: true,
      rowData: [],
      editingKey: "",
      receiptTypes: [],
      students: [],
      visibleParentFile: false,
      data: [],
      searchText: "",
      searchInput: null,
      searchedColumn: "",
      targetKeys: [],
      previewVisible: false,
      previewImage: "",
      value: 1,
      loadingData: true,
      visibleData: false,
      indeterminate: true,
      checkAll: false,
      columns: [
        {
          title: this.$t("recette.nom"),
          dataIndex: "parent",
          key: "parent",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "parent",
          },
          onFilter: (value, record) => {
            let clientName =
              record.parent.father.firstName +
              " " +
              record.parent.father.lastName +
              " " +
              record.parent.mother.firstName +
              " " +
              record.parent.mother.lastName;
            return clientName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },

        {
          title: this.$t("recette.telephone"),
          dataIndex: "parent",
          key: "phone",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "phone",
          },
          onFilter: (value, record) =>
            (record.parent.father.phone + " " + record.parent.mother.phone)
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.enfant"),
          dataIndex: "student",
          key: "student",
          width: "150px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "student",
          },
          onFilter: (value, record) => {
            let studentName =
              record.student.firstName + " " + record.student.lastName;
            return studentName.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.activite"),
          dataIndex: "name",
          key: "name",
          width: "300px",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "name",
          },
          onFilter: (value, record) =>
            (record.service.name + ": " + record.name)
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
      ],
      filtredTable: [],
    };
  },
  methods: {
    getMonthName,
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    getStudent(id) {
      try {
        return this.students.filter((s) => s._id == id)[0];
      } catch {
        return null;
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    generatePdf() {
      this.$gtag.event("Imp etatImpayesActivites", {
        event_category: "Impression PDF",
        event_label: "declarations:etatImpayesActivites",
        value: 1,
      });

      let selectMonth = document.getElementById("selectMonth").innerText;
      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();
      let monthNumber = date.getMonth();

      var titre = "Etat des impayés - Activités";
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setLanguage("ar");
      doc.setFontSize(15);
      doc.text(70, 60, titre);
      doc.text(89, 70, selectMonth);
      doc.setFontSize(10);
      try {
        doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      } catch (err) {
        console.log(err);
      }
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      var somme = 0;

      for (let i = 0; i < this.filtredTable.length; i++) {
        let inscriptionFees = 0;
        if (this.filtredTable[i].inscription === false) {
          inscriptionFees = this.filtredTable[i].activity.inscription;
        }
        let currentIndex = 0;
        let monthsUnpaied = 0;
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            if (this.filtredTable[i].months[key] === false) monthsUnpaied++;
          }
        }

        if (currentIndex === 0) {
          currentIndex++;
          if (
            this.filtredTable[i].inscription === false &&
            inscriptionFees !== 0
          )
            monthsUnpaied++;
          if (monthsUnpaied !== 0) {
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content: this.filtredTable[i].parent.father.phone,
              styles: { valign: "middle" },
            });
            arr.unshift({
              rowSpan: monthsUnpaied,
              content:
                this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName,
              styles: { valign: "middle" },
            });
          }
        }
        if (
          this.filtredTable[i].inscription === false &&
          inscriptionFees !== 0
        ) {
          if (!currentIndex) {
            arr.push(
              this.filtredTable[i].parent.father.firstName +
                " " +
                this.filtredTable[i].parent.father.lastName
            );
            arr.push(this.filtredTable[i].parent.father.phone);
            arr.push(
              this.filtredTable[i].student.firstName +
                " " +
                this.filtredTable[i].student.lastName
            );
          }
          arr.push("Inscription " + this.filtredTable[i].name);
          arr.push(
            this.filtredTable[i].activity.inscription *
              (1 - this.filtredTable[i].reduction.inscription / 100)
          );
          somme += parseFloat(
            this.filtredTable[i].activity.inscription *
              (1 - this.filtredTable[i].reduction.inscription / 100)
          );
          array.push(arr);
          arr = [];
        }
        if (this.filtredTable[i].months) {
          for (var key of Object.keys(this.filtredTable[i].months)) {
            let paymentDate = new Date(key);
            let monthName = paymentDate.toLocaleString("fr-FR", {
              month: "long",
            });
            if (this.filtredTable[i].months[key] === false) {
              // arr.push(this.filtredTable[i].student.firstName + ' ' + this.filtredTable[i].student.lastName);
              arr.push(monthName + " - " + this.filtredTable[i].name);
              arr.push(
                this.filtredTable[i].activity.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
              somme += parseFloat(
                this.filtredTable[i].activity.monthly *
                  (1 - this.filtredTable[i].reduction.monthly / 100)
              );
              //colspan
              array.push(arr);
              arr = [];
            }
          }
        }
      }
      doc.autoTable({
        theme: "grid",
        styles: { font: "Amiri" },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Nom client", "Téléphone", "Stagiaire", "Frais", "Montant"]],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(somme, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            parseFloat(somme).toFixed(2),
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Etat_des_impayés_activité.pdf");
    },
    /*
    handleChangeSelectMois(value) {
      const rowData = [];
      this.rowData.forEach((elem) => rowData.push({ ...elem }));
      if (value == "all") this.data = rowData;
      else {
        let newData = rowData.filter((elem) => !elem.once);
        this.data = newData.map((elem) => {
          const month = Object();
          Object.keys(elem.months).forEach((key) => {
            if (parseInt(key.split("-")[1]) == parseInt(value))
              month[key] = elem.months[key];
          });
          return {
            ...elem,
            months: month,
            status: getStatus({
              inscription: elem.inscription,
              months: month,
            }),
          };
        });
      }
      this.filtredTable = this.data;
    },
    */
    showThisRow(record) {
      if (!record.inscription) return true;
      else {
        for (const key in record.months) {
          if (!record.months[key]) return true;
        }
        return false;
      }
    },
    paymentDate(insStart, month) {
      const dayOfMonth = moment(insStart).format("DD");
      const formattedResult = moment(month)
        .set("date", dayOfMonth)
        .format("DD-MM-YYYY");

      return formattedResult;
    },
    handleChangeSelectMois(value) {
      const rowData = [...this.rowData];
      if (value == "all") {
        this.data = rowData;
        this.mF = true;
      } else {
        this.mF = false;
        this.data = rowData
          .filter(
            (elem) =>
              !elem.once &&
              typeof elem.months === "object" &&
              elem.months !== null
          )
          .map((elem) => {
            const months = { ...elem.months };
            let newMonths = {};
            Object.keys(months).forEach((key) => {
              if (parseInt(key.split("-")[1]) == parseInt(value))
                newMonths[key] = months[key];
            });
            return {
              ...elem,
              months: newMonths,
              status: getStatus({
                inscription: elem.inscription,
                months: newMonths,
              }),
            };
          })
          .filter(
            (elem) =>
              Object.keys(elem.months).length > 0 && this.showThisRow(elem)
          );
      }
      this.filtredTable = this.data;
    },
  },
};
</script>
